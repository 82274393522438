import React, {Fragment, useCallback, useContext, useState} from 'react';
import BookmarkContext from '../../_helper/Bookmark';
import GridClass from '../../Components/Application/BookMarks/Common/GridClass';
import {Card, CardBody, TabContent, TabPane,} from 'reactstrap';
import ClassSchedule from "./ClassSchedule";
import ModuleDataLoop from "./ModuleDataLoop";
import FinalExamDataLoop from "./FinalExamDataLoop";


const CourseBehaviorTabs = ({courseTitle}) => {
    const {activeTabss} = useContext(BookmarkContext);
    const [gridView, setgridView] = useState(true);

    // eslint-disable-next-line
    const callback = useCallback((grid) => {
        return setgridView(grid);
    });

    return (
        <Fragment>
            <TabContent activeTab={activeTabss}>
                <TabPane tabId="1">
                    <Card className="mb-0">
                        <GridClass title="Course Module" callback={callback}/>
                        <CardBody className="pb-0">
                            <div className={`details-bookmark text-center ${gridView ? '' : 'list-bookmark'}`}>
                                <ModuleDataLoop courseTitle={courseTitle}/>
                            </div>
                        </CardBody>
                    </Card>
                </TabPane>
                <TabPane tabId="2">
                    <Card className="mb-0">
                        <GridClass title="Course Class" callback={callback}/>
                        <CardBody>
                            <div className={`details-bookmark text-center ${gridView ? '' : 'list-bookmark'}`}>
                                {/*<FavDataLoop />*/}
                                <ClassSchedule/>
                            </div>
                        </CardBody>
                    </Card>
                </TabPane>
                <TabPane tabId="3">
                    <Card className="mb-0">
                        <GridClass title="Course Final Exam" callback={callback}/>
                        <CardBody className="pb-0">
                            <div className={`details-bookmark text-center ${gridView ? '' : 'list-bookmark'}`}>
                                <FinalExamDataLoop courseTitle={courseTitle}/>
                            </div>
                        </CardBody>
                    </Card>
                </TabPane>
            </TabContent>
        </Fragment>
    );
};
export default CourseBehaviorTabs;