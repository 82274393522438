import {Col, Container, Row} from "reactstrap";
import React, {useContext, useEffect, useState} from "react";
import CheckContext from "../../_helper/Customizer";
import CustomizerContext from "../../_helper/Customizer";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {API_COURSE_BATCH, API_COURSE_EXAM, API_COURSE_EXAM_SUBMISSION} from "../../api/server";
import {H3} from "../../AbstractElements";
import SweetAlert from "sweetalert2";
import ExamSidebar from "./ExamSidebar";
import Questions from "./Questions";
import {parseDate} from "../../Utils/date";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";

const ExamPage = () => {
        const {toggleSidebar} = useContext(CheckContext);
        const {toggleIcon} = useContext(CustomizerContext);
        const {session} = useParams()
        const navigate = useNavigate();

        const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

        const [started, setStarted] = useState(false)

        const [moduleChapterData, setModuleChapterData] = useState({
            id: 0,
            modules_name: "",
            modules_files_id: "",
            module_group_id: 0,
            exam_id: 0,
            exam_time: 99999,
            exam_start_date: new Date().toISOString(),
            exam_due_date: new Date().toISOString(),
        })

        const [submissionData, setSubmissionData] = useState({
            id: 0,
            date: new Date().toISOString(),
            started_date: null,
            full_name: "",
            answers: [],
            batch_id: 0,
            user_id: 0,
            exam_id: 0,
            user_action: "",
            submitted: false,
            calculated: false,
            total_point: 0,
            max_point: 0,
            session_key: "",
            module_id: 0,
            exam_type: 0
        })

        const [batchData, setBatchData] = useState({
            id: 0,
            title: "",
            course_id: 0,
            exam_id: 0,
            exam_time: 9999,
            exam_start_date: "2022-09-04T00:00:00+07:00",
            exam_due_date: "2022-09-04T00:00:00+07:00",
        })

        useEffect(() => {
            loadCourseExam()
        }, [session])

        //
        // useEffect(() => {
        //     loadCourseExam()
        // }, [])

        useEffect(() => {
            if (submissionData.id !== 0 && moduleChapterData.id !== 0) {
                if (submissionData.submitted === true ) {
                    if ( submissionData.module_id !== 0 &&  Date.now() >= parseDate(moduleChapterData.exam_due_date).getTime()) {
                        showEscapeDialog()
                    } else if (Date.now() >= parseDate(batchData.exam_due_date).getTime())  {
                        showEscapeDialog()
                    }
                }
            }
        }, [submissionData, moduleChapterData, batchData])

        useEffect(() => {
            if (started) {
                handleOnDraftExam();
            }
        }, [submissionData])


        useEffect(() => {
            if (submissionData.id !== 0) {
                showConfirmationDialog()
            }
        }, [submissionData.id])

        useEffect(() => {
            toggleSidebar(true);
        }, [toggleIcon])


        const handleOnQuestionChanged = (idx) => {
            setCurrentQuestionIndex(idx)
        }

        const showEscapeDialog = () => {
            SweetAlert.fire({
                title: 'Exam Finished',
                text: 'This exam period is overdue or you\'ve submitted this exam',
                icon: 'error',
                confirmButtonText: 'Continue',
            })
                .then((result) => {
                    navigate(`${process.env.PUBLIC_URL}/account`)
                });
        }

        const showConfirmationDialog = () => {
            SweetAlert.fire({
                title: 'Warning',
                text: 'When Taking Exam All your Keyboard Input, Tab Changes, and Some Others Information will be recorded',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Start Exam',
                cancelButtonText: 'Cancel',
            })
                .then((result) => {
                        if (!result.value) {
                            toggleSidebar(false);
                            navigate(`${process.env.PUBLIC_URL}/account`)
                        } else {
                            console.log("ANS", submissionData.answers)
                            if (submissionData.started_date === null) {
                                const newSubmissionData = {
                                    ...submissionData,
                                    started_date: new Date().toISOString()
                                }

                                axios.put(API_COURSE_EXAM_SUBMISSION + "/", {...newSubmissionData}, {withCredentials: true})
                                    .then(() => {
                                        setSubmissionData({...newSubmissionData})
                                    })
                                    .catch(() => {
                                        SweetAlert.fire({
                                            title: 'Failed',
                                            text: 'Failed Start Exam, Try Again',
                                            icon: 'warning',
                                            confirmButtonText: 'OK',
                                        }).then(() => {
                                            navigate("/account")
                                        })
                                    })
                            }
                            setStarted(true)
                        }
                    }
                )
        }

        const loadCourseExam = () => {
            axios.get(API_COURSE_EXAM + `/${session}`, {withCredentials: true})
                .then((r) => {
                    if (r.status === 200) {
                        return r.data
                    }
                })
                .then((json) => {
                    console.log("CEX", json)

                    if (json.data.data) {
                        setSubmissionData(json.data.data.exam_submission_data[0])
                        setModuleChapterData(json.data.data.module_chapter_data[0])
                    }
                })
        }


        const loadBatchData = (batch_id) => {
            axios.get(API_COURSE_BATCH + `/?pagination=${CreatePaginationEncoding(1,0)}&filter=${CreateFilterEncoding({id : batch_id})}`, {withCredentials: true})
                .then((r) => {
                    if (r.status === 200) {
                        return r.data
                    }
                })
                .then((json) => {
                    if (json.data.data) {
                        console.log("BATCH", json.data.data)
                        setBatchData(json.data.data[0])
                    }
                })
        }

        const handleOnSubmitExam = () => {
            axios.put(API_COURSE_EXAM_SUBMISSION + "/", {
                ...submissionData,
                submitted: true
            }, {withCredentials: true}).then(() => {
                SweetAlert.fire({
                    title: 'Success!',
                    text: 'Exam Submission Completed!',
                    icon: 'success'
                }).then(() => {
                    toggleSidebar(true);
                    navigate(`${process.env.PUBLIC_URL}/course/${batchData.course_id}/${batchData.id}`)
                })
            })
        }


        useEffect(() => {
            loadBatchData(submissionData.batch_id)
        }, [submissionData.batch_id])

        const handleOnDraftExam = () => {
            axios.put(API_COURSE_EXAM_SUBMISSION + "/", {
                ...submissionData,
            }, {withCredentials: true}).then(() => {
                console.log(submissionData)
            })
        }

        return (
            submissionData.submitted !== true && <>
                <Container fluid={true}>
                    <div className="page-header">
                        <Row>
                            <Col sm="6">
                                <H3>Exam - {moduleChapterData.modules_name}</H3>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Container fluid={true}>
                    <Row>
                        <ExamSidebar
                            submissionData={submissionData}
                            batchData={batchData}
                            onSubmitExam={() => handleOnSubmitExam()}
                            onQuestionChanged={handleOnQuestionChanged}
                            currentQuestionIndex={currentQuestionIndex}
                            moduleChapterData={moduleChapterData}
                        />

                        <Questions colClass="col-xl-9 xl-60 box-col-8"
                                   questData={submissionData.answers[currentQuestionIndex]}
                                   questNumber={currentQuestionIndex + 1}
                                   onAnswerChange={(e) => {
                                       let q = submissionData.answers
                                       q[currentQuestionIndex] = e

                                       setSubmissionData({
                                           ...submissionData,
                                           answers: q
                                       })
                                   }}
                                   questionCount={submissionData.answers.length}
                                   currentQuestionIndex={currentQuestionIndex}
                                   setCurrentQuestionIndex={setCurrentQuestionIndex}
                        />
                    </Row>
                </Container>
            </>
        );
    }
;

export default ExamPage;