import {Btn, H5} from '../../../AbstractElements';
import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Collapse, Label, Row} from 'reactstrap';
import Countdown from "react-countdown";
import {parseDate} from "../../../Utils/date";

const Actions = ({onSubmitExam, questionCount = 0, submissionData, moduleChapterData, batchData}) => {
    const [isFilter, setIsFilter] = useState(true);

    const [startDate, setStartDate] = useState(Date.now())
    const [examTime, setExamTime] = useState(99999)

    useEffect(() => {
        if (submissionData.started_date !== null) {
            setStartDate(parseDate(submissionData.started_date).getTime())
        }
    }, [submissionData.started_date])

    const handleOnSubmitExam = () => {
        onSubmitExam()
    }

    useEffect(() => {

        console.log("ET", submissionData.exam_type)

        switch (submissionData.exam_type) {
            case 1:
                setExamTime(parseInt(moduleChapterData.exam_time))
                break
            case 2:
                setExamTime(parseInt(batchData.exam_time))
                break
        }
    }, [submissionData.exam_type, moduleChapterData.exam_time, batchData.exam_time])


    const [elapsedTime, setElapsedTime] = useState(1)

    const TimerRenderer = ({hours, minutes, seconds, completed}) => {
        if (completed) {
            onSubmitExam()
            // console.log("EXAM SUBMITTED DUE Time End")
            return (
                <Label className="d-block">
                    Time End
                </Label>
            );
        } else {
            return (<Label className="d-block">
                {hours} : {minutes} : {seconds}
            </Label>)
        }
    };

    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <H5 attrH5={{className: 'mb-0 p-0'}}>
                            <Btn attrBtn={{
                                className: 'btn btn-link ps-0',
                                color: 'transparent',
                                onClick: () => setIsFilter(!isFilter)
                            }}>Exam</Btn>
                        </H5>
                    </CardHeader>

                    <Collapse isOpen={isFilter}>
                        <CardBody className="filter-cards-view animate-chk">
                            <Row className="justify-content-between">
                                <Col xs="auto">
                                    <Label className="d-block">
                                        <b>Time</b>
                                    </Label>
                                    <Label className="d-block">
                                        <b>Question</b>
                                    </Label>
                                </Col>
                                <Col xs="auto">
                                    <Countdown
                                        date={startDate + examTime * 60 * 1000}
                                        // date={Date.now() + 900000}
                                        // autoStart={true}
                                        // controlled={false}
                                        renderer={TimerRenderer}
                                        // onTick={({seconds}) => {
                                        //     if (parseInt(seconds) <= 0) {
                                        //         setElapsedTime(elapsedTime + 1)
                                        //     }
                                        // }}
                                    />

                                    <Label className="d-block">
                                        {questionCount}
                                    </Label>
                                </Col>
                            </Row>

                            <hr/>
                            <Btn attrBtn={{
                                onClick: () => {
                                    handleOnSubmitExam()
                                },
                                className: "text-center",
                                color: 'primary',
                                size: 'small',
                                active: true,
                                disabled: false,
                                style: {
                                    width: "100%"
                                }
                            }}
                            >
                                Submit Exam
                            </Btn>
                        </CardBody>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default Actions;