import {Col, Container, Row} from "reactstrap";
import {Breadcrumbs} from "../../AbstractElements";
import MyProfileEdit from "../../Components/Application/Users/UsersEdit/MyProfile";
import EditMyProfile from "../../Components/Application/Users/UsersEdit/EditmyProfile";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_USER_CURRENT} from "../../api/server";

const AccountPage = () => {
    const filesInitialData = {
        cv: [],
        photo: [],
        education_certificate: [],
    }

    const [accountData, setAccountData] = useState({
            id: 0,
            email: "",
            password: "",
            role: 0,
            full_name: "",
            gender: 0,
            birth_date: "0001-01-01T00:00:00Z",
            address: "",
            company_name: "",
            last_education: "",
            education_major: "",
            university: "",
            phone_number: "",
            whatsapp_number: "",

            cv_files_id: "",
            last_education_certificate_files_id: "",
            photo_files_id: ""
        }
    )

    // const [files, setFiles] = useState(filesInitialData)

    const [filesPhoto, setFilesPhoto] = useState([])
    const [filesCV, setFilesCV] = useState([])
    const [filesEducationCertificate, setFilesEducationCertificate] = useState([])

    useEffect(() => {
        axios.get(API_USER_CURRENT, {withCredentials: true}).then((r) => {
            if (r.status === 200) {
                return r.data
            }
        }).then((json) => {
            const serverAccountData = json.data.data[0]
            setAccountData({
                ...accountData,
                ...serverAccountData
            })

            if (serverAccountData.photo_files_id !== "") {
                setFilesPhoto([{
                    source: serverAccountData.photo_files_id,
                    options: {type: 'local'}
                }])
            }

            if (serverAccountData.cv_files_id !== "") {
                setFilesCV([{
                    source: serverAccountData.cv_files_id,
                    options: {type: 'local'}
                }])
            }

            if (serverAccountData.last_education_certificate_files_id !== "") {
                setFilesEducationCertificate([{
                    source: serverAccountData.last_education_certificate_files_id,
                    options: {type: 'local'}
                }])
            }
        })
    }, [])


    return (
        <>
            <Breadcrumbs mainTitle="User Edit" parent="Users" title="User Edit"/>
            <Container fluid={true}>
                <div className="edit-profile">
                    <Row>
                        <Col xl="4">
                            <MyProfileEdit accountData={accountData} setAccountData={setAccountData}/>
                        </Col>
                        <Col xl="8">
                            <EditMyProfile accountData={accountData} setAccountData={setAccountData}
                                           filesPhoto={filesPhoto} setFilesPhoto={setFilesPhoto}
                                           filesCV={filesCV} setFilesCV={setFilesCV}
                                           filesEducationCertificate={filesEducationCertificate}
                                           setFilesEducationCertificate={setFilesEducationCertificate}/>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}

export default AccountPage