import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row,} from 'reactstrap';
import axios from "axios";
import {API_CDN_DEFAULT, API_COURSE_BATCH_MODULE, API_COURSE_EXAM_SUBMISSION_FINAL_EXAM} from "../../api/server";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Alerts, Btn, H3, H5, H6, P} from "../../AbstractElements";
import {toast} from "react-toastify";
import {parseDate} from "../../Utils/date";

const FinalExamDataLoop = ({courseTitle}) => {
    let {id, batch_id} = useParams();

    const [data, setData] = useState({
        batch_data: {
            id: 0,
            title: "",
            course_id: 0,
            exam_id: 0,
            exam_time: 0,
            exam_start_date: new Date(-9999 * 3600 * 1000).toISOString(),
            exam_due_date: new Date().toISOString(),
        },
        exam_is_submitted: false,
        exam_total_point: 0,
        submission_session_key: "",
        user_id: 1,
    })
    const nav = useNavigate();

    useEffect(() => {
        axios.get(API_COURSE_EXAM_SUBMISSION_FINAL_EXAM + `/${batch_id}`, {withCredentials: true}).then((r) => {
            if (r.status === 200) {
                return r.data
            }
        }).then((json) => {
            console.log("FEDA", json.data["data"])
            json.data["data"] && setData(json.data["data"])
        })
    }, [])

    return (
        <Fragment>
            <Row id="bookmarkData">

                {parseDate(data.batch_data.exam_start_date).getTime() > Date.now() && <Alerts attrAlert={{color: 'danger dark'}}>
                    Final Exam Not yet Available
                </Alerts>}

                {(parseDate(data.batch_data.exam_start_date).getTime() < Date.now() && parseDate(data.batch_data.exam_due_date).getTime() > Date.now()) && !data.exam_is_submitted && <Alerts attrAlert={{color: 'success dark'}}>
                    Final Already Available, Take your exam
                    before {parseDate(data.batch_data.exam_due_date).toLocaleDateString('en-US', {dateStyle: "full"})}
                </Alerts>}

                {parseDate(data.batch_data.exam_due_date).getTime() < Date.now() && !data.exam_is_submitted && <Alerts attrAlert={{color: 'danger dark'}}>
                    Final Exam is Overdue
                </Alerts>}

                {data.exam_is_submitted && <Card className="profile-greeting__2">
                    <CardBody className="text-center m-t-10">
                        <P isOpen>Congratulations, you've Finish your Exam!!</P>
                        <H3 attrH3={{className: 'font-light'}}>Grade : {data.exam_total_point.toFixed(2)}</H3>
                    </CardBody>
                </Card>}


                {(parseDate(data.batch_data.exam_start_date).getTime() < Date.now() && parseDate(data.batch_data.exam_due_date).getTime() > Date.now()) && !data.exam_is_submitted && <Btn attrBtn={{
                    color: 'secondary',
                    size: 'md',
                    active: true,
                    // disabled: data.is_submitted === true || Date.now() > parseDate(data.exam_due_date).getTime() || Date.now() < parseDate(data.exam_start_date).getTime(),
                    style: {
                        width: "100%"
                    },
                    onClick: () => {
                        nav(`${process.env.PUBLIC_URL}/exam/` + data.submission_session_key)
                    }
                }}
                >
                    Take Exam
                </Btn>}
                {/*{moduleData.length > 0 ?*/}
                {/*    (*/}
                {/*        moduleData.map((v, idx) => {*/}
                {/*            return (*/}
                {/*                <Col sm="12" xl="12" key={idx}>*/}
                {/*                    <Card>*/}
                {/*                        <CardHeader className="b-l-primary">*/}
                {/*                            <H5>{v.module_title}</H5>*/}
                {/*                        </CardHeader>*/}
                {/*                        <CardBody>*/}
                {/*                            <Row>*/}
                {/*                                {v.module_chapters.map((data, index) => {*/}
                {/*                                    return (*/}
                {/*                                        <>*/}
                {/*                                            <Col sm="12" md="8"*/}
                {/*                                                 key={index}>*/}
                {/*                                                <Card className="bookmark-card o-hidden">*/}
                {/*                                                    <div className="details-website">*/}
                {/*                                                        <div className="desciption-data">*/}
                {/*                                                            <div className="title-bookmark__no_hover">*/}
                {/*                                                                <H6> {data.modules_name} </H6>*/}
                {/*                                                                <P> {courseTitle} - Regular Exam </P>*/}
                {/*                                                                <P> Started at*/}
                {/*                                                                    : {parseDate(data.exam_start_date).toLocaleDateString()} </P>*/}
                {/*                                                                <P> End at*/}
                {/*                                                                    : {parseDate(data.exam_due_date).toLocaleDateString()} </P>*/}
                {/*                                                            </div>*/}
                {/*                                                        </div>*/}
                {/*                                                    </div>*/}
                {/*                                                </Card>*/}
                {/*                                            </Col>*/}
                {/*                                            <Col md={4} sm={12}>*/}
                {/*                                                <Btn attrBtn={{*/}
                {/*                                                    color: 'primary',*/}
                {/*                                                    size: 'md',*/}
                {/*                                                    active: true,*/}
                {/*                                                    disabled: false,*/}
                {/*                                                    style: {*/}
                {/*                                                        width: "100%"*/}
                {/*                                                    },*/}
                {/*                                                    className: "m-b-5",*/}
                {/*                                                    onClick: () => {*/}
                {/*                                                        if (data.modules_files_id !== "") {*/}
                {/*                                                            window.open(API_CDN_DEFAULT + "/download?load=" + data.modules_files_id, '_blank');*/}
                {/*                                                        } else {*/}
                {/*                                                            toast.success('No Module Files Found', {*/}
                {/*                                                                position: toast.POSITION.BOTTOM_CENTER*/}
                {/*                                                            });*/}
                {/*                                                        }*/}
                {/*                                                    }*/}
                {/*                                                }}*/}
                {/*                                                >*/}
                {/*                                                    Download*/}
                {/*                                                </Btn>*/}
                {/*                                                <Btn attrBtn={{*/}
                {/*                                                    color: 'secondary',*/}
                {/*                                                    size: 'md',*/}
                {/*                                                    active: true,*/}
                {/*                                                    disabled: data.is_submitted === true || Date.now() > parseDate(data.exam_due_date).getTime() || Date.now() < parseDate(data.exam_start_date).getTime(),*/}
                {/*                                                    style: {*/}
                {/*                                                        width: "100%"*/}
                {/*                                                    },*/}
                {/*                                                    onClick: () => {*/}
                {/*                                                        nav(`${process.env.PUBLIC_URL}/exam/` + data.exam_session)*/}
                {/*                                                    }*/}
                {/*                                                }}*/}
                {/*                                                >*/}
                {/*                                                    {*/}
                {/*                                                        ExamButtonText(data)*/}
                {/*                                                    }*/}
                {/*                                                    /!*{!data.is_submitted ? "Take Exam" : `Grade : ${data.exam_total_point.toFixed(2)}`}*!/*/}
                {/*                                                </Btn>*/}
                {/*                                            </Col>*/}
                {/*                                        </>*/}
                {/*                                    );*/}
                {/*                                })}*/}
                {/*                            </Row>*/}
                {/*                        </CardBody>*/}
                {/*                    </Card>*/}
                {/*                </Col>*/}
                {/*            )*/}
                {/*        })*/}
                {/*    )*/}
                {/*    : (*/}
                {/*        <Col xl="12">*/}
                {/*            <div className="no-favourite">*/}
                {/*                <span>No Module Found</span>*/}
                {/*            </div>*/}
                {/*            {' '}*/}
                {/*        </Col>*/}
                {/*    )}*/}
            </Row>
        </Fragment>
    );
};
export default FinalExamDataLoop;
