import {Badges, Btn, H5} from '../../../AbstractElements';
import React, {Fragment, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Collapse, Row} from 'reactstrap';

const QuestionSelector = ({questionList = [], onQuestionNumberClicked, currentQuestionIndex}) => {
    const [isFilter, setIsFilter] = useState(true);

    const handleQuestionNumberClicked = (idx) => {
        onQuestionNumberClicked(idx)
    }

    const getBadgeColor = (answers = [], idx) => {

        if (currentQuestionIndex === idx) {
            return 'primary'
        }

        const answerState = answers.find((a) => a.checked === true)

        if (answerState === undefined) {
            return 'info'
        } else {
            return 'success'
        }
    }

    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <H5 attrH5={{className: 'mb-0 p-0'}}>
                            <Btn attrBtn={{
                                className: 'btn btn-link ps-0',
                                color: 'transparent',
                                onClick: () => setIsFilter(!isFilter)
                            }}>Question</Btn>
                        </H5>
                    </CardHeader>

                    <Collapse isOpen={isFilter}>
                        <CardBody className="filter-cards-view animate-chk">

                            <Row
                                className="justify-content-start align-content-center align-items-center align-self-center">
                                {questionList.map((v, i) => {
                                    return (
                                        <Col md={2}>
                                            <Badges attrBadge={{
                                                color: getBadgeColor(v.answers, i),
                                                style: {fontSize: 12, margin: 0, cursor: "pointer"},
                                                className: "m-b-5",
                                                onClick: () => handleQuestionNumberClicked(i)
                                                // onClick: () => getBadgeColor(v.answers)
                                            }}>{i + 1}</Badges>
                                        </Col>
                                    )
                                })}
                            </Row>

                        </CardBody>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default QuestionSelector;