import React, {Fragment, useEffect, useState} from 'react';
import {Settings} from 'react-feather';
import {H6, Image, P} from '../../AbstractElements';
import man from '../../assets/images/dashboard/1.png';
import axios from "axios";
import {API_USER_CURRENT} from "../../api/server";

const Profile = () => {
    const [userData, setUserData] = useState({
        address: "",
        bio: "",
        email: "",
        first_name: "",
        id: 0,
        last_name: "",
        password: "",
        role: 2,
        username: ""
    })
    useEffect(() => {
        axios.get(API_USER_CURRENT, {withCredentials: true})
            .then((r) => {
                if (r.status === 200) {
                    return r.data
                }
            })
            .then((json) => {
                console.log("UD", json.data)
                setUserData({...json.data["data"][0]})
            })
    }, []);

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <a className="setting-primary" href="#">
                    <Settings/>
                </a>
                {/*<Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}>*/}
                <Image attrImage={{
                    className: 'img-90 rounded-circle',
                    src: man,
                    alt: ''
                }}/>
                <H6 attrH6={{className: 'mt-3 f-14 f-w-600'}}>{userData.full_name && `${userData.full_name}`}</H6>
                {/*</Link>*/}
                <P attrPara={{className: 'mb-0 font-roboto'}}>User</P>
                {/*<UL attrUL={{className: 'flex-row simple-list'}}>*/}
                {/*    <LI><span><span className="counter"></span>6</span>*/}
                {/*        <P>Course</P>*/}
                {/*    </LI>*/}
                {/*    <LI><span><span className="counter"></span>100</span>*/}
                {/*        <P>Exam</P>*/}
                {/*    </LI>*/}
                {/*    <LI><span>30</span>*/}
                {/*        <P>Certificate</P>*/}
                {/*    </LI>*/}
                {/*</UL>*/}
            </div>
        </Fragment>
    );
};

export default Profile;