import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Media, Row} from 'reactstrap';
import {Breadcrumbs, H5, P} from "../../AbstractElements";
import {useParams} from "react-router-dom";
import NavTab from "./NavTab";
import axios from "axios";
import {API_COURSE, API_COURSE_BATCH} from "../../api/server";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import CourseBehaviorTabs from "./CourseBehaviorTabs";


const MyCoursePage = () => {
    const [Sidebar, setSidebar] = useState(false);
    const OnClickHandel = () => {
        setSidebar(!Sidebar);
    };

    let {id, batch_id} = useParams();

    const [courseData, setCourseData] = useState({
        id: 0,
        title: ""
    })

    const [batchData, setBatchData] = useState({

    })


    useEffect(() => {
        axios.get(API_COURSE_BATCH + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
            id: parseInt(batch_id)
        }), {withCredentials: true}).then((r) => {
            if (r.status === 200) {
                return r.data
            }
        }).then((json) => {
            console.log("NJSON", json)
            json.data["data"][0] && setBatchData(json.data["data"][0])
        })
    }, [])


    useEffect(() => {
        axios.get(API_COURSE + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
            id: parseInt(id)
        }), {withCredentials: true}).then((r) => {
            if (r.status === 200) {
                return r.data
            }
        }).then((json) => {
            json.data["data"][0] && setCourseData(json.data["data"][0])
        })
    }, [])


    return (
        <>
            <Breadcrumbs mainTitle="Course Title" parent="Dashboard" title="Course Title"/>
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="3" className="box-col-6 xl-30">
                            <div className="email-sidebar">
                                <a className="btn btn-primary email-sidebar-toggle" href="#javascript"
                                   onClick={OnClickHandel}>Course Title</a>
                                <div className={`email-left-aside ${Sidebar ? 'open' : ''}`}>
                                    <Card>
                                        <CardBody>
                                            <div className="email-app-sidebar left-bookmark">
                                                <Media>
                                                    <Media body>
                                                        <P>Course</P>
                                                        <H5 attrH6={{
                                                            className: 'f-w-600',
                                                            color: "primary"
                                                        }}>{courseData.title}</H5>
                                                    </Media>
                                                </Media>

                                                <Media body>
                                                    <P>Batch</P>
                                                    <H5 attrH6={{
                                                        className: 'f-w-500',
                                                        color: "primary"
                                                    }}>{batchData.title}</H5>
                                                </Media>
                                                <NavTab/>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                        <Col xl="9" md="12" className="box-col-12 xl-70">
                            <div className="email-right-aside bookmark-tabcontent">
                                <Card className="email-body radius-left">
                                    <div className="pl-0">
                                        <CourseBehaviorTabs courseTitle={courseData.title}/>
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};

export default MyCoursePage;