// export const API_HOST = 'http://localhost:4488'
// export const API_HOST = 'https://projects.zukff.com/009/api'
// export const API_HOST = 'https://api.wsc.zukff.com'
export const API_HOST = 'https://api.weldingstudycenter.com'
//
export const API_DATASTORE = API_HOST + '/datastore'

export const API_USER = API_DATASTORE + '/user'

export const API_USER_CURRENT = API_USER + '/current'

export const API_USER_LOGIN = API_USER + '/authorize'

export const API_USER_LOGIN_WITH_ADMIN = API_USER + '/authorize-with-admin'

export const API_COURSE = `${API_DATASTORE}/course`

export const API_COURSE_BATCH = `${API_DATASTORE}/course-batch`

export const API_COURSE_EXAM = `${API_DATASTORE}/course-exam`

export const API_COURSE_EXAM_SUBMISSION = `${API_DATASTORE}/course-exam-submission`

export const API_COURSE_EXAM_SUBMISSION_FINAL_EXAM = `${API_DATASTORE}/course-exam-submission/final-exam`

export const API_COURSE_BATCH_ABSENCE = `${API_COURSE_BATCH}/absence`

export const API_COURSE_BATCH_MODULE = `${API_DATASTORE}/course-batch/module`

export const API_USER_CERTIFICATE = `${API_DATASTORE}/user-certificate`

export const API_CDN = API_HOST + '/cdn'

export const API_CDN_DEFAULT = API_CDN + "/default"