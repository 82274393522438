import GalleryContext from "../../_helper/Gallery";
import {useContext, useState, Fragment, useCallback} from "react";
import {Container, Row} from "reactstrap";
import {Breadcrumbs} from "../../AbstractElements";
import ListOfImageDesc from "../../Components/Gallery/ImageGallery/ListOfImgDesc";
import SingleImage from "../../Components/Gallery/ImageGallery/SingleImage";
import SingleCertificateImage from "../../Components/Gallery/ImageGallery/SingleCertificateImage";

const CertificatePage = () => {
    const { images, smallImages } = useContext(GalleryContext);
    const initilindex = { index: 0, isOpen: false };
    const [photoIndex, setPhotoIndex] = useState(initilindex);

    const [certifId, setCertifId] = useState("")

    const callback = useCallback((photo) => {
        setPhotoIndex(photo);
    });

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Certificate" parent="Dashboard" title="Certificate" />
            <Container fluid={true}>
                <Row>
                    <ListOfImageDesc smallImages={smallImages} setPhotoIndex={callback} photoIndex={photoIndex} withDesc={true} setCertifId={setCertifId} />
                </Row>
            </Container>
            <SingleCertificateImage photoIndex={photoIndex} setPhotoIndex={callback} images={images} certificateFileID={certifId}/>
        </Fragment>
    );
};


export default CertificatePage;