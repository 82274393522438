import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import { routes } from './Routes';
import AppLayout from '../Layout/Layout';
import {LS_USER_KEY} from "../Utils/jwt";

const LayoutRoutes = () => {
    const [authKey, setAuthKey] = useState(localStorage.getItem(LS_USER_KEY))
    const location = useLocation();
    const nav = useNavigate();

    useEffect(() => {
        const _authKey = localStorage.getItem(LS_USER_KEY)
        setAuthKey(_authKey)

        if ((authKey === null || authKey === '') && location.pathname !== '/login') {
            nav(`${process.env.PUBLIC_URL}/login`)
        }
    }, [])

  return (
    <>
      <Routes>
        {routes.map(({ path, Component }, i) => (
          <Route element={<AppLayout />} key={i}>
            {/*<Route path={'/'} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default`} />} />*/}
            <Route path={path} element={Component} />
          </Route>
        ))}
      </Routes>
    </>
  );
};

export default LayoutRoutes;