import React, {Fragment, useEffect, useState} from 'react';
import {Btn, H4, H6, P} from '../../AbstractElements';
import {Form, FormGroup, Input, Label} from 'reactstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import {Link, useNavigate} from 'react-router-dom';
import {LS_USER_KEY} from '../../Utils/jwt';
import {API_USER_LOGIN, API_USER_LOGIN_WITH_ADMIN} from "../../api/server";
import {useCookies} from 'react-cookie'

const LoginWithAdminPages = () => {
    const [showPassword, setShowPassword] = useState(false)

    const [form, setForms] = useState({
        email: '',
        password: ''
    })

    const [cookies, setCookie] = useCookies(['XJ-AUTH'])


    const nav = useNavigate()

    useEffect(() => {
        // const _authKey = localStorage.getItem(LS_USER_KEY)
        // if (_authKey !== null && _authKey !== '') {
        //     nav('/content/homepage/carousel');
        // }
    }, [])

    const handleLogin = (e) => {
        e.preventDefault()
        axios.post(API_USER_LOGIN_WITH_ADMIN, {
            ...form
        }).then(r => {
            if (r.status === 200) {
                SweetAlert.fire({title: 'Good job!', text: 'Login Success!', icon: 'success'}).then(() => {
                    localStorage.setItem(LS_USER_KEY, r.data['data']['token']);
                    setCookie('XJ-AUTH', r.data['data']['token'], {path: '/', domain: 'weldingstudycenter.com'})
                }).then(() => {
                    nav(`${process.env.PUBLIC_URL}/account`);
                })
            }
        }).catch((err) => {
            SweetAlert.fire({
                title: 'Login Failed!',
                text: 'Usename / Password is Wrong',
                icon: 'error'
            }).then(() => {
                console.log(err)
            })
        })
    }

    return (
        <Fragment>
            <div className="login-card">
                <Form className="theme-form login-form" onSubmit={handleLogin}>
                    <H4>Login</H4>
                    <H6>Welcome back! Log in to your account.</H6>
                    <FormGroup>
                        <Label>User Address</Label>
                        <div className="input-group"><span className="input-group-text"><i
                            className="icon-user"></i></span>
                            <Input onChange={(e) => setForms({...form, target_email: e.target.value})}
                                   className="form-control" type="text" required="" placeholder="User Email"/>
                        </div>
                    </FormGroup>
                    <hr/>
                    <FormGroup>
                        <Label>Admin Email Address</Label>
                        <div className="input-group"><span className="input-group-text"><i
                            className="icon-user"></i></span>
                            <Input onChange={(e) => setForms({...form, email: e.target.value})}
                                   className="form-control" type="text" required="" placeholder="Email"/>
                        </div>
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label>Password</Label>
                        <div className="input-group"><span className="input-group-text"><i
                            className="icon-lock"></i></span>
                            <Input onChange={(e) => setForms({...form, password: e.target.value})}
                                   className="form-control" type={showPassword ? 'text' : 'password'} required={true}
                                   placeholder="*********"/>
                            <div className="show-hide" onClick={() => {
                                setShowPassword(!showPassword)
                            }}><span className={showPassword ? 'hide' : 'show'}/></div>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <div className="checkbox">
                            <Input id="checkbox1" type="checkbox"/>
                            <Label className="text-muted" for="checkbox1">Remember password</Label>
                        </div>
                        {/*    <a className="link" href="forget-password.html">Forgot password?</a>*/}
                    </FormGroup>
                    <FormGroup>
                        <Btn attrBtn={{className: 'btn-block', color: 'primary', type: 'submit'}}>Sign in</Btn>
                    </FormGroup>
                    <P>Don't have account?<Link className="ms-2" to={`${process.env.PUBLIC_URL}/register`}>Create
                        Account</Link></P>
                </Form>
            </div>
        </Fragment>
    );
};

export default LoginWithAdminPages;