import React, { Fragment, useCallback, useContext, useState } from 'react';
import { Bookmark, PlusCircle } from 'react-feather';
import { Nav, NavItem } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { NewBookmark, Views, CreatedByMe, Favourites, SharedWithMe, MyBookmark, Tags, Newsletter, Notification, } from '../../Constant';
import BookmarkContext from '../../_helper/Bookmark';
import BookmarkModal from "../../Components/Application/BookMarks/ModalBookMark";
import ModalTag from "../../Components/Application/BookMarks/ModalTag";


const NavTab = () => {
    const { mybookmarkData, setActiveTabss } = useContext(BookmarkContext);
    const [addModal, setaddModal] = useState(false);
    const [tagModal, setTagModal] = useState(false);

    const [activeTab, setActiveTab] = useState('1');

    const addToggle = () => {
        setaddModal(!addModal);
    };

    const tagToggle = () => {
        setTagModal(!tagModal);
    };
    const onHandleClick = (id) => {
        setActiveTabss(id);
        setActiveTab(id);
    };

    // eslint-disable-next-line
    const addToggleCallback = useCallback((toggle) => {-
        setaddModal(toggle);
    });
    return (
        <Fragment>
            <Nav className="main-menu" role="tablist">
                <li> <hr /> </li>
                <NavItem>
                    <span className="main-title">Course Material</span>
                </NavItem>
                <NavItem>
                    <a href="#javascript" className={activeTab === '1' ? 'active' : ''} onClick={() => onHandleClick('1')}>
                        <span className="title"> Course Module </span></a>
                </NavItem>
                <NavItem>
                    <a href="#javascript" className={activeTab === '2' ? 'active' : ''} onClick={() => onHandleClick('2')} >
                        <span className="title">Course Classes </span>  </a>
                </NavItem>
                <NavItem className="m-t-15">
                    <a href="#javascript" className={activeTab === '3' ? 'active' : ''} onClick={() => onHandleClick('3')} >
                        <span className="title">Final Exam </span>  </a>
                </NavItem>
            </Nav>
        </Fragment>
    );
};
export default NavTab;