import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row,} from 'reactstrap';
import axios from "axios";
import {API_CDN_DEFAULT, API_COURSE_BATCH_MODULE, API_COURSE_EXAM_SUBMISSION} from "../../api/server";
import {useNavigate, useParams} from "react-router-dom";
import {Btn, H5, H6, P} from "../../AbstractElements";
import {toast} from "react-toastify";
import {parseDate, parseDateOnly} from "../../Utils/date";

const ModuleDataLoop = ({courseTitle}) => {
    let {id, batch_id} = useParams();

    const [moduleData, setModuleData] = useState([])
    const nav = useNavigate();

    const ExamButtonText = (data) => {
        if (data.submitted) {
            return "Grade : " + data.total_point.toFixed(2)
        } else {
            if (Date.parse(data.exam_due_date) < Date.now()) {
                return "Overdue"
            }

            if (Date.parse(data.exam_start_date) > Date.now()) {
                return "Not Opened"
            }

            return "Take Exam"
        }
    }

    useEffect(() => {
        axios.get(API_COURSE_BATCH_MODULE + `/${batch_id}`, {withCredentials: true}).then((r) => {
            if (r.status === 200) {
                return r.data
            }
        }).then((json) => {
            json.data["data"] && json.data["data"].length > 0 && setModuleData(json.data["data"])
        })
    }, [])

    const handleTakeExam = (data) => {
        if (data.session_key !== undefined && data.session_key !== null && data.session_key !== "") {
            nav(`${process.env.PUBLIC_URL}/exam/` + data.session_key)
        } else {
            console.log("DATA", data)

            axios.post(API_COURSE_EXAM_SUBMISSION, {
                batch_id : batch_id,
                exam_id : 0,
                exam_type : 0,
                module_id : 0,
            }, {withCredentials: true}).then((r) => {
                if (r.status === 200) {
                    return r.data
                }
            }).then((json) => {
                json.data["data"] && json.data["data"].length > 0 && setModuleData(json.data["data"])
            })
        }
    }

    return (
        <Fragment>
            <Row id="bookmarkData">
                {moduleData.length > 0 ?
                    (
                        moduleData.map((v, idx) => {
                            return (
                                <Col sm="12" xl="12" key={idx}>
                                    <Card>
                                        <CardHeader className="b-l-primary">
                                            <H5>{v.module_title}</H5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                {v.module_chapters.map((data, index) => {
                                                    return (
                                                        <>
                                                            <Col sm="12" md="8"
                                                                 key={index}>
                                                                <Card className="bookmark-card o-hidden">
                                                                    <div className="details-website">
                                                                        <div className="desciption-data">
                                                                            <div className="title-bookmark__no_hover">
                                                                                <H6> {data.modules_name} </H6>
                                                                                <P> {courseTitle} - Regular Exam </P>
                                                                                <P> Started at
                                                                                    : {parseDateOnly(data.exam_start_date).toLocaleDateString()} </P>
                                                                                <P> End at
                                                                                    : {parseDateOnly(data.exam_due_date).toLocaleDateString()} </P>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </Col>
                                                            <Col md={4} sm={12}>
                                                                <Btn attrBtn={{
                                                                    color: 'primary',
                                                                    size: 'md',
                                                                    active: true,
                                                                    disabled: false,
                                                                    style: {
                                                                        width: "100%"
                                                                    },
                                                                    className: "m-b-5",
                                                                    onClick: () => {
                                                                        if (data.modules_files_id !== "") {
                                                                            window.open(API_CDN_DEFAULT + "/download?load=" + data.modules_files_id, '_blank');
                                                                        } else {
                                                                            toast.success('No Module Files Found', {
                                                                                position: toast.POSITION.BOTTOM_CENTER
                                                                            });
                                                                        }
                                                                    }
                                                                }}
                                                                >
                                                                    Download
                                                                </Btn>
                                                                <Btn attrBtn={{
                                                                    color: 'secondary',
                                                                    size: 'md',
                                                                    active: true,
                                                                    disabled: data.submitted === true || (Date.now() > Date.parse(data.exam_due_date) || Date.now() < Date.parse(data.exam_start_date)),                                                                    style: {
                                                                        width: "100%"
                                                                    },
                                                                    onClick: () => handleTakeExam(data)
                                                                    // onClick: () => {
                                                                    //     nav(`${process.env.PUBLIC_URL}/exam/` + data.session_key)
                                                                    // }
                                                                }}
                                                                >
                                                                    {
                                                                        ExamButtonText(data)
                                                                    }
                                                                </Btn>
                                                            </Col>
                                                        </>
                                                    );
                                                })}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })
                    )
                    : (
                        <Col xl="12">
                            <div className="no-favourite">
                                <span>No Module Found</span>
                            </div>
                            {' '}
                        </Col>
                    )}
            </Row>
        </Fragment>
    );
};
export default ModuleDataLoop;