import React, {Fragment, useEffect, useState} from 'react';
import {Col, Row} from 'reactstrap';
import {Btn} from "../../../AbstractElements";
import Actions from "./Actions";
import QuestionSelector from "./QuestionSelector";

const ExamSidebar = ({submissionData, batchData, moduleChapterData, onQuestionChanged, onSubmitExam, currentQuestionIndex}) => {
    const [IsOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth <= 1199) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    });

    const OnHandelClick = () => {
        setIsOpen(!IsOpen);
    };

    const handleOnQuestionChange = (idx) => {
        onQuestionChanged(idx)
    }

    const handleOnSubmitExam = () => {
        onSubmitExam()
    }
    return (
        <Fragment>
            <Col xl="3" className="box-col-4 xl-40">
                <div className="job-sidebar">
                    {isMobile && <Btn attrBtn={{
                        color: 'primary',
                        size: 'small',
                        active: true,
                        disabled: false,
                        style: {
                            marginTop: "16px",
                            marginBottom: "16px",
                            width: "100%"
                        },
                        onClick: OnHandelClick
                    }}
                    >
                        Exam Menu
                    </Btn>}
                    <div className={`job-left-aside custom-scrollbar ${IsOpen ? 'open' : ''}`}>
                        <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                            <Row>
                                <Actions
                                    batchData={batchData}
                                    onSubmitExam={() => handleOnSubmitExam()}
                                    submissionData={submissionData}
                                    questionCount={submissionData.answers.length}
                                    moduleChapterData={moduleChapterData}
                                />
                                <QuestionSelector questionList={submissionData.answers}
                                                  currentQuestionIndex={currentQuestionIndex}
                                                  onQuestionNumberClicked={handleOnQuestionChange}/>
                            </Row>
                        </div>
                    </div>
                </div>
            </Col>
        </Fragment>
    );
};

export default ExamSidebar;