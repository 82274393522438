import {Btn, H6, P} from '../../AbstractElements';
import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardBody, Col, Input, Label, Row} from 'reactstrap';
import {CkHTMLComponent} from "../../Components/Others/CkHTMLComponent";

const Questions = ({
                       questionCount = 0,
                       colClass,
                       questData,
                       questNumber = 0,
                       onAnswerChange,
                       currentQuestionIndex,
                       setCurrentQuestionIndex
                   }) => {

    const [currentQuestion, setCurrentQuestion] = useState()

    useEffect(() => {
        setCurrentQuestion(questData)
    }, [questData])

    return (
        <Fragment>
            <Col className={colClass}>
                <Card key={'1'} className={'ribbon-vertical-left-wrapper'}>
                    <div className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-primary">{questNumber}</div>
                    <div className="job-search">
                        <CardBody>
                            <div className="media m-b-20">
                                <div className="media-body">
                                    <H6 attrH6={{className: 'f-w-600 font-primary'}}>
                                        Question {questNumber}
                                    </H6>
                                    {/*<P>Choose {currentQuestion && currentQuestion.max_answer} Answer</P>*/}
                                    <P>The Multiple Choice Answer Can Be 1 Answer or more than 1 Answers</P>
                                    <hr/>
                                    <CkHTMLComponent html={currentQuestion && currentQuestion.question}/>


                                    {/*<P>{currentQuestion && currentQuestion.question}</P>*/}
                                </div>
                            </div>
                        </CardBody>
                    </div>
                </Card>

                <Card key={'1-A'}>
                    <div className="job-search">
                        <CardBody>
                            <div className="media m-b-20">
                                <div className="media-body">
                                    {currentQuestion && currentQuestion.answers.map((vv, ii) => {
                                        return (
                                            <Row className="justify-content-start align-self-center align-items-start">
                                                <Col xs='auto'>
                                                    <Input className="checkbox_animated" id={`chk-ani--${ii}`}
                                                           type="checkbox"
                                                           checked={vv.checked}
                                                           onChange={(e) => {
                                                               let _newAnswer = currentQuestion.answers && currentQuestion.answers

                                                               _newAnswer[ii] = {
                                                                   ..._newAnswer[ii],
                                                                   checked: e.target.checked
                                                               }

                                                               setCurrentQuestion({
                                                                   ...currentQuestion,
                                                                   answers: _newAnswer
                                                               })

                                                               onAnswerChange({
                                                                   ...currentQuestion,
                                                                   answers: _newAnswer
                                                               })
                                                           }}/>
                                                </Col>
                                                <Col xs='auto'>
                                                    <Label className="d-block" for={`chk-ani--${ii}`}>
                                                        {/*{vv.code}.*/}
                                                        {String.fromCharCode(65 + ii)}.

                                                    </Label>
                                                </Col>
                                                <Col xs='auto'>
                                                    <Label className="d-block" for={`chk-ani--${ii}`}>
                                                        <P>
                                                            <CkHTMLComponent html={vv.content}/>
                                                        </P>
                                                    </Label>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                            </div>
                        </CardBody>
                    </div>
                </Card>
                <Row>
                    <Col md={"6"}>
                        <Btn attrBtn={{
                            onClick: () => {
                                setCurrentQuestionIndex(currentQuestionIndex - 1)
                            },
                            className: "text-center",
                            color: 'primary',
                            size: 'small',
                            active: true,
                            disabled: currentQuestionIndex === 0,
                            style: {
                                width: "100%"
                            }
                        }}> Previous </Btn>
                    </Col>
                    <Col md={"6"}>
                        <Btn attrBtn={{
                            onClick: () => {
                                setCurrentQuestionIndex(currentQuestionIndex + 1)
                            },
                            className: "text-center",
                            color: 'primary',
                            size: 'small',
                            active: true,
                            disabled: currentQuestionIndex >= questionCount - 1,
                            style: {
                                width: "100%"
                            }
                        }}> Next </Btn>
                    </Col>
                </Row>
            </Col>

        </Fragment>
    );
};

export default Questions;