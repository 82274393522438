import MyCoursePage from "../Pages/Course";
import CertificatePage from "../Pages/Certificate";
import AccountPage from "../Pages/Account";
import {Navigate} from "react-router-dom";
import ExamListPage from "../Pages/ExamList";
import ExamPage from "../Pages/Exam";
import LoginPages from "../Pages/Login/LoginForm";
import RegisterPages from "../Pages/Register/RegisterForm";
import LoginWithAdminPages from "../Pages/Login/LoginFormWithAdmin";

export const routes = [
    {
        path: `${process.env.PUBLIC_URL}/`, Component: () => {
            return <Navigate to={`${process.env.PUBLIC_URL}/account`}/>
        }
    },
    {path: `${process.env.PUBLIC_URL}/course/:id/:batch_id`, Component: <MyCoursePage/>},
    // {path: `${process.env.PUBLIC_URL}/exam`, Component: <ExamListPage/>},
    {path: `${process.env.PUBLIC_URL}/exam/:session`, Component: <ExamPage/>},
    {path: `${process.env.PUBLIC_URL}/certificate`, Component: <CertificatePage/>},
    {path: `${process.env.PUBLIC_URL}/account`, Component: <AccountPage/>},
];

export const unprotectedRoutes = [
    {path: `${process.env.PUBLIC_URL}/login/use-admin`, Component: <LoginWithAdminPages/>},
    {path: `${process.env.PUBLIC_URL}/login`, Component: <LoginPages/>},
    {path: `${process.env.PUBLIC_URL}/register`, Component: <RegisterPages/>},
]
