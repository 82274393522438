import React, {Fragment, useEffect, useState} from "react";
import {Card, CardBody, Col, Media} from "reactstrap";
import HeaderCard from "../../Common/Component/HeaderCard";
import {Btn} from "../../../AbstractElements";
import axios from "axios";
import {API_CDN_DEFAULT, API_USER_CERTIFICATE, API_USER_CURRENT} from "../../../api/server";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../../Utils/Base64/request";

const ListOfImageDesc = ({smallImages, setPhotoIndex, photoIndex, setCertifId}) => {

    const [certificateData, setCertificateData] = useState([])
    const [userData, setUserData] = useState({
        address: "",
        bio: "",
        email: "",
        first_name: "",
        id: 0,
        last_name: "",
        password: "",
        role: 2,
        username: ""
    })
    useEffect(() => {
        axios.get(API_USER_CURRENT, {withCredentials: true})
            .then((r) => {
                if (r.status === 200) {
                    return r.data
                }
            })
            .then((json) => {
                console.log("UD", json.data)
                setUserData({...json.data["data"][0]})
            })
    }, []);

    useEffect(() => {
        if (userData.id !== 0) {
            axios.get(API_USER_CERTIFICATE + "/?pagination=" + CreatePaginationEncoding(99999999, 0) + "&filter=" + CreateFilterEncoding({
                user_id: userData.id
            }))
                .then(r => {
                    if (r.status === 200) {
                        return r.data["data"]
                    }
                }).then(json => {
                if (json.data) {
                    console.log("CERT", json.data)
                    setCertificateData(json.data.length > 0 ? json.data : [])
                }
            })
        }
    }, [userData])

    return (
        <Fragment>
            {certificateData.map((v) => {
                return (
                    <Col sm="12">
                        <Card>
                            <HeaderCard title="My Certificate"/>
                            <CardBody className="my-gallery row gallery-with-description">
                                <figure className="col-xl-3 col-sm-6">
                                    <a href="#javascript" data-size="1600x950">
                                        <Media
                                            src={require(`../../../assets/images/certificate-base-image.jpg`)}
                                            alt="Gallery"
                                            className="img-thumbnail"
                                            onClick={() => {
                                                axios.get(`${API_CDN_DEFAULT}?load=${v.certificate_files_id}`).then((r) => {
                                                    if (r.status === 404 ||!r.headers["content-type"].includes("image")) {
                                                        window.open(`${API_CDN_DEFAULT}/download?load=${v.certificate_files_id}`, "_blank")
                                                    }
                                                    setCertifId(v.certificate_files_id)
                                                    setPhotoIndex({...photoIndex, index: 0, isOpen: true})
                                                }

                                            )}}
                                        />
                                        <div className="caption">
                                            <h4>Certificate of Completion</h4>

                                            <Btn attrBtn={{
                                                onClick: () => {
                                                    window.open(`${API_CDN_DEFAULT}/download?load=${v.certificate_files_id}`, "_blank")
                                                },
                                                color: 'primary',
                                                size: 'small',
                                                active: true,
                                                disabled: false,
                                                style: {
                                                    marginTop: "16px",
                                                    width: "100%"
                                                }
                                            }}
                                            >
                                                Download
                                            </Btn>
                                        </div>
                                    </a>
                                </figure>
                            </CardBody>
                        </Card>
                    </Col>
                )
            })}

        </Fragment>
    )
}

export default ListOfImageDesc;
