import React from 'react';
import { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loader from '../Layout/Loader';
import LayoutRoutes from '../Route/LayoutRoutes';
import {unprotectedRoutes} from "./Routes";


const Routers = () => {

        useEffect(() => {
                let abortController = new AbortController();
                console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
                console.disableYellowBox = true;
                return () => {
                        abortController.abort();
                };

        }, []);

        return (
                <BrowserRouter >
                        <>
                                <Suspense fallback={<Loader />}>
                                        <Routes>
                                                <Route path={`/*`} element={<LayoutRoutes />} />
                                                {unprotectedRoutes.map(({ path, Component }, i) => (
                                                    <Route path={path} element={Component} key={i}/>
                                                ))}
                                        </Routes>
                                </Suspense>
                        </>
                </BrowserRouter>
        );
};

export default Routers;