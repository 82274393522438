import React, {Fragment, useEffect, useState} from 'react';
import {Btn, H4, H6, P} from '../../AbstractElements';
import {Form, FormGroup, Input, Label} from 'reactstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import {Link, useNavigate} from 'react-router-dom';
import {API_USER} from "../../api/server";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {genderOptions} from "./gender_options";

const RegisterPages = () => {
    const [showPassword, setShowPassword] = useState(false)

    const [form, setForms] = useState({
        id: 0,
        email: "",
        password: "",
        full_name: "",
        gender: 1,
        birth_date: "0001-01-01T00:00:00Z",
    })

    const nav = useNavigate()

    useEffect(() => {
        // const _authKey = localStorage.getItem(LS_USER_KEY)
        // if (_authKey !== null && _authKey !== '') {
        //     nav('/content/homepage/carousel');
        // }
    }, [])

    const handleLogin = (e) => {
        e.preventDefault()
        axios.post(API_USER + "/", {
            ...form
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire({title: 'Good job!', text: 'We\'ve record your data!', icon: 'success'}).then(() => {
                    nav(`${process.env.PUBLIC_URL}/login`);
                })
            }
        }).catch((err) => {
            SweetAlert.fire({
                title: 'Sign Up Failed!',
                text: 'Hang up, we have some errors.',
                icon: 'error'
            }).then(() => {
            })
        })
    }

    const [birthDate, setBirthDate] = useState(new Date())
    const handleBirthDateChange = date => {
        setBirthDate(date);
        setForms({
            ...form,
            birth_date: date.toISOString()
        })
    };

    return (
        <Fragment>
            <div className="login-card">
                <Form className="theme-form login-form" onSubmit={handleLogin}>
                    <H4>Sign Up</H4>
                    <H6>Hello, New User! Fill the Forms to Join us.</H6>
                    <FormGroup>
                        <Label>Full Name</Label>
                        <div className="input-group"><span className="input-group-text"><i
                            className="icon-user"></i></span>
                            <Input onChange={(e) => setForms({...form, full_name: e.target.value})}
                                   className="form-control" type="text" required="" placeholder="Full Name"/>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Birth Date</Label>
                        <DatePicker className="form-control digits" selected={birthDate}
                                    onChange={handleBirthDateChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Gender</Label>
                        <Select
                            options={genderOptions}
                            className="js-example-basic-single col-sm-12"
                            onChange={(gender) => {
                                setForms({
                                    ...form,
                                    gender: gender.value
                                })
                            }}
                        />
                    </FormGroup>

                    <hr/>
                    <FormGroup>
                        <Label>Email Address</Label>
                        <div className="input-group"><span className="input-group-text"><i
                            className="icon-user"></i></span>
                            <Input onChange={(e) => setForms({...form, email: e.target.value})}
                                   className="form-control" type="email" required="" placeholder="Email"/>
                        </div>
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label>Password</Label>
                        <div className="input-group"><span className="input-group-text"><i
                            className="icon-lock"></i></span>
                            <Input onChange={(e) => setForms({...form, password: e.target.value})}
                                   className="form-control" type={showPassword ? 'text' : 'password'} required={true}
                                   placeholder="*********"/>
                            <div className="show-hide" onClick={() => {
                                setShowPassword(!showPassword)
                            }}><span className={showPassword ? 'hide' : 'show'}/></div>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Btn attrBtn={{
                            className: 'btn-block btn-sign-in',
                            color: 'primary',
                            type: 'submit'
                        }}>Sign in</Btn>
                    </FormGroup>
                    <P>Already have account?<Link className="ms-2"
                                                  to={`${process.env.PUBLIC_URL}/login`}>Login</Link></P>
                </Form>
            </div>
        </Fragment>
    );
};

export default RegisterPages;