import React, {useContext, useEffect, useState} from 'react';
import { Col, Card, CardHeader, Table } from 'reactstrap';
import TableContext from "../../_helper/Table";
import {VerticalBorders} from "../../Constant";
import {Btn, H5} from "../../AbstractElements";
import axios from "axios";
import {API_COURSE_BATCH_ABSENCE, API_COURSE_BATCH_MODULE, API_USER_CURRENT} from "../../api/server";
import {useParams} from "react-router-dom";
import {dateIsToday, parseDate} from "../../Utils/date";

const ClassSchedule = () => {
    let { id, batch_id } = useParams();

    const [data, setData] = useState([])


    useEffect(() => {
        axios.get(API_COURSE_BATCH_ABSENCE + `/${batch_id}`, {withCredentials : true}).then((r) => {
            if (r.status === 200) {
                return r.data
            }
        }).then((json) => {
            json.data["data"] && json.data["data"].length > 0 && setData(json.data["data"])
        })
    }, [])

    const joinButton = (url) => (<Btn attrBtn={{
        color: 'primary',
        size: 'xs',
        active: true,
        disabled: false,
        style : {
            width: "100%"
        },
        onClick : () => {
            var win = window.open(url, '_blank');
            win.focus();
        }
    }}
    >
        Join
    </Btn>)

    const statusSet = ["N/A", "Present", "Absent"]

    return (
        <Col sm="12">
            <Card>
                <CardHeader>
                    <H5>Class Schedule</H5>
                </CardHeader>
                <div className="table-responsive">
                    <Table className="table-border-vertical">
                        <thead>
                            <tr>
                                <th scope="col">{'#'}</th>
                                <th scope="col">{'Class Title'}</th>
                                <th scope="col">{'Speaker'}</th>
                                <th scope="col">{'Date'}</th>
                                <th scope="col">{'Absence'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/*{*/}
                            {/*    data.map((item) =>*/}
                            {data.map((v,i)  => {
                                return (
                                    <tr key="1">
                                        <th scope="row">{i + 1}</th>
                                        <td>{v.title}</td>
                                        <td>{v.speaker}</td>
                                        <td>{parseDate(v.date).toUTCString().replace(" GMT", "")}</td>
                                        <td>{v.status === 0 ? dateIsToday(parseDate(v.date)) ? joinButton(v.url) : "N/A" : statusSet[v.status]}</td>
                                    </tr>
                                )
                            })}

                            {/*<tr key="2">*/}
                            {/*    <th scope="row">2</th>*/}
                            {/*    <td>Class B</td>*/}
                            {/*    <td>Dr. Speaker A</td>*/}
                            {/*    <td>15 March 2002</td>*/}
                            {/*    <td>Absent</td>*/}
                            {/*</tr>*/}
                            {/*<tr key="3">*/}
                            {/*    <th scope="row">3</th>*/}
                            {/*    <td>Class C</td>*/}
                            {/*    <td>Dr. Speaker A</td>*/}
                            {/*    <td>16 March 2002</td>*/}
                            {/*    <td>Permit</td>*/}
                            {/*</tr>*/}
                            {/*<tr key="4">*/}
                            {/*    <th scope="row">3</th>*/}
                            {/*    <td>Class C</td>*/}
                            {/*    <td>Dr. Speaker A</td>*/}
                            {/*    <td>16 March 2002</td>*/}
                            {/*    <td>*/}
                            {/*        <Btn attrBtn={{*/}
                            {/*            color: 'primary',*/}
                            {/*            size: 'xs',*/}
                            {/*            active: true,*/}
                            {/*            disabled: false,*/}
                            {/*            style : {*/}
                            {/*                width: "100%"*/}
                            {/*            },*/}
                            {/*            onClick : () => {*/}
                            {/*                axios.get(API_USER_CURRENT + "/course", {withCredentials: true}) .then((r) => {*/}
                            {/*                    console.log(r.status)*/}
                            {/*                    console.log(r.data)*/}
                            {/*                })*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*        >*/}
                            {/*            Join*/}
                            {/*    </Btn>*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                            {/*    )*/}
                            {/*}*/}
                        </tbody>
                    </Table>
                </div>
            </Card>
        </Col>
    );
};

export default ClassSchedule;