import React from 'react';
import Routers from './Route';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';
import ProjectProvider from "./_helper/Project/ProjectProvider";
import GalleryProvider from "./_helper/Gallery/GalleryProvider";
import FaqProvider from "./_helper/Faq/FaqProvider";
import EmailProvider from "./_helper/Faq/FaqProvider";
import LearningProvider from "./_helper/Learning/LearningProvider";
import JobSearchProvider from "./_helper/JobSearch/JobSearchProvider";
import KanbanProvider from "./_helper/Kanbanboard/KanbanboardProvider";
import WishListProvider from "./_helper/Ecommerce/Wishlist/WishlistProvider";
import FilterProvider from "./_helper/Ecommerce/Filter/FilterProvider";
import CartProvider from "./_helper/Ecommerce/Cart/CardProvider";
import ProductProvider from "./_helper/Ecommerce/Product/ProductProvider";
import SearchResultProvider from "./_helper/SearchResult/SearchResult";
import TodoProvider from "./_helper/Todo/TodoProvider";
import BookmarkProvider from "./_helper/Bookmark/BookmarkProvider";
import TableProvider from "./_helper/Table/TableProvider";
import TaskProvider from "./_helper/Task/TaskProvider";
import ContactProvider from "./_helper/Contact/ContactProvider";
import ChatProvider from "./_helper/Chat/ChatProvider";
import GoogleChartProvider from "./_helper/GoogleChart/GoogleChartProvider";
import ChartjsProvider from "./_helper/Chartjs/ChartProvider";
import ChartistProvider from "./_helper/Chartist/ChartistProvider";

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginGetFile from 'filepond-plugin-get-file';

import {registerPlugin} from 'react-filepond';

registerPlugin(
    FilePondPluginGetFile,
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageEdit,
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType,
    FilePondPluginImageTransform,
)

const App = () => (
    <div className="App">
        <CustomizerProvider>
            <FaqProvider>
                <LearningProvider>
                    <JobSearchProvider>
                        <KanbanProvider>
                            <WishListProvider>
                                <FilterProvider>
                                    <CartProvider>
                                        <ProductProvider>
                                            <SearchResultProvider>
                                                <EmailProvider>
                                                    <TodoProvider>
                                                        <BookmarkProvider>
                                                            <TableProvider>
                                                                <GalleryProvider>
                                                                    <TaskProvider>
                                                                        <ContactProvider>
                                                                            <ChatProvider>
                                                                                <ProjectProvider>
                                                                                    <GoogleChartProvider>
                                                                                        <ChartjsProvider>
                                                                                            <ChartistProvider>
                                                                                                <AnimationThemeProvider>
                                                                                                    <Routers/>
                                                                                                </AnimationThemeProvider>
                                                                                            </ChartistProvider>
                                                                                        </ChartjsProvider>
                                                                                    </GoogleChartProvider>
                                                                                </ProjectProvider>
                                                                            </ChatProvider>
                                                                        </ContactProvider>
                                                                    </TaskProvider>
                                                                </GalleryProvider>
                                                            </TableProvider>
                                                        </BookmarkProvider>
                                                    </TodoProvider>
                                                </EmailProvider>
                                            </SearchResultProvider>
                                        </ProductProvider>
                                    </CartProvider>
                                </FilterProvider>
                            </WishListProvider>
                        </KanbanProvider>
                    </JobSearchProvider>
                </LearningProvider>
            </FaqProvider>
        </CustomizerProvider>
    </div>
);

export default App;
