import {Airplay, BookOpen, Edit3, Home, User} from 'react-feather';
import axios from "axios";
import {API_USER_CURRENT} from "../../api/server";
import {useState} from "react";

const getActiveCourse = () => {
    // // TODO : Async to Server get all user Active Course
    // const [courses, setCourses] = useState()
    // // let courses = []
    // axios.get(API_USER_CURRENT + "/course", {withCredentials: true}) .then((r) => {
    //     console.log()
    //
    //     if (r.status === 200) {
    //         setCourses(r.data.data["data"])
    //         console.log(courses)
    //     }
    // })
    // // return ["Course A", "Course B", "Course C"]
    // return courses
}

export let MENUITEMS = [
    {
        menutitle: 'Dashboard',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'My Course', icon: Airplay, type: 'sub', active: false, children: []
            },
            // {
            //     title: 'Exam', badge: 'badge badge-success mb-0 white-text', badgetxt: '3', icon: Edit3, type: 'link', path: `${process.env.PUBLIC_URL}/exam`
            // },
            {
                title: 'Certificate', icon: BookOpen, type: 'link', path: `${process.env.PUBLIC_URL}/certificate`
            },
        ]
    },
    {
        Items: [
            {
                title: 'Account', icon: User, type: 'link', path: `${process.env.PUBLIC_URL}/account`
            },
        ]
    },
]